<template>
  <div class="c-footer-containar">
    <div class="c-card">
      <div class="c-purchase-footer">
        <div class="flex flex-row-reverse flex-wrap mr-4">
          <div class="flex justify-content-between flex-wrap row-gap-1 column-gap-8">
            <div class="c-purchase-total-column">
              <label>{{ t("purchase.footer.total-items") }}</label>
              <span data-testid="purchase-order-items-summary">{{
                n(sumTotalLines, "currency", currencyIsoComputed)
              }}</span>
            </div>

            <div class="c-purchase-total-column">
              <label for="purchase-agreed-freight">{{ t("purchase.footer.freight") }}</label>
              <div>{{ n(freightPriceComputed, "currency", currencyIsoComputed) }}</div>
            </div>

            <div class="c-purchase-total-column">
              <label>{{ t("purchase.footer.total-sum") }}</label>
              <span class="c-purchase-order-total-summary" data-testid="purchase-order-total-summary">{{
                n(totalSum, "currency", currencyIsoComputed)
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  sumTotalLines: number;
  totalSum: number;
  shippingPrice: number;
  purchaseOrderStatus?: PurchaseOrderStatus;
  allowEdit: boolean;
  currencyIso: string;
}>();

const emit = defineEmits<{
  (e: "update:shippingPrice", value: number): void;
  (e: "reCalculatePurchaseOrderTotal"): void;
}>();

const { t, n } = useI18n();

const currencyIsoComputed = computed(() => {
  return props.currencyIso ? props.currencyIso : "NOK";
});

const freightPriceComputed = computed({
  get() {
    return props.shippingPrice ?? 0;
  },
  set(value: number) {
    if (value != null) {
      emit("update:shippingPrice", value);
      emit("reCalculatePurchaseOrderTotal");
    }
  },
});
</script>

<style scoped lang="scss">
.c-footer-containar {
  position: sticky;
  padding: 0rem 1.2rem 1rem 1.2rem;
  bottom: 0;
  width: 100%;
  background-color: var(--surface-a);
  z-index: 1;
  background-color: var(--portal-main-bg-color);

  .c-card {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: none;
    margin-top: 0;
    padding-top: 0.7rem;
  }
}

.c-purchase-footer {
  background: var(--footer-order-color-bg);
  border-radius: 4px;
  width: 100%;
}
.c-purchase-footer label {
  color: var(--footer-order-color-test);
  font-weight: normal;
}

.c-purchase-total-column {
  display: flex;
  flex-direction: column;
  height: 4rem;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.c-purchase-order-total-summary {
  font-size: 1.3rem;
}
</style>
