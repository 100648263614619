<template>
  <PurchaseOrderSearchToolbar
    @purchaseRefresh="onRefreshList"
    @onToggleFilterSidebar="onToggleFilterSidebar"
    :showSidebar="showSidebar"
    v-model:filters="filtersComputed"
  />
  <div class="c-purchase-search-result">
    <div class="flex">
      <div class="flex-auto">
        <div class="c-card">
          <Suspense>
            <PurchaseOrderList
              :purchaseOrders="purchaseOrders"
              :loading="loading"
              :totalHits="totalHits"
              :pageSize="pageSize"
              :page="page"
              :sortField="sortField"
              :sortOrder="sortOrder"
              @update:sortOrder="onUpdateSortPurchaseOrder"
              @update:page="onUpdatePage"
              @update:pageSize="onUpdatePageSize"
              @downloadPdfBtnClicked="downloadPdf"
              @update:sortField="onUpdateSortField"
              @purchaseRefresh="onRefreshList"
            />
          </Suspense>
        </div>
      </div>
      <div class="pb-2" :class="showSidebar ? 'pl-5' : ''">
        <PurchaseOrderFilterSidebar :showSidebar="showSidebar" v-model:filters="filtersComputed" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, computed, onActivated, onDeactivated } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";

import PurchaseOrderSearchToolbar from "../components/PurchaseOrderSearchToolbar.vue";
import PurchaseOrderFilterSidebar from "../components/PurchaseOrderFilterSidebar.vue";
import PurchaseOrderList from "../components/PurchaseOrderList.vue";

import { useSearch } from "@/api/search/SearchService";
import { SearchPurchaseOrder } from "@/models/search/purchase-order/SearchPurchaseOrder";
import { PurchaseOrderSearchRequest } from "@/models/search/purchase-order/PurchaseOrderSearchRequest";
import { PurchaseOrderSearchFilters } from "@/models/search/purchase-order/PurchaseOrderSearchFilters";

import { useDocument } from "@/api/document/DocumentService";
import { useCumulusToast } from "@cumulus/toast";

const { t } = useI18n();

const route = useRoute();
const toast = useCumulusToast(useToast());
const { purchaseOrderSearch, clearPurchaseOrderSearchCache } = useSearch();
const { getPurchaseOrderUrl } = useDocument();

const pageSize = ref<number>(50);
const loading = ref(false);
const showSidebar = ref(true);
const query = ref("");
const purchaseOrders = ref<SearchPurchaseOrder[]>([]);
const totalHits = ref(0);
const page = ref(1);
const sortField = ref("");
const sortOrder = ref(-1);
const filters = ref<PurchaseOrderSearchFilters>(new PurchaseOrderSearchFilters());

watch(
  () => route.query.search,
  async (newQuery) => {
    if (newQuery !== undefined) {
      query.value = decodeURIComponent((newQuery as string) ?? "");
      await search();
    }
  }
);

onMounted(async () => {
  query.value = decodeURIComponent((route.query.search as string) ?? "");
  await search();
});

onActivated(async () => {
  showSidebar.value = true;
});

onDeactivated(() => {
  showSidebar.value = false;
});

const onRefreshList = async () => {
  loading.value = true;
  clearPurchaseOrderSearchCache();
  await search();
};

const onToggleFilterSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const filtersComputed = computed<PurchaseOrderSearchFilters>({
  get: () => filters.value,
  set: async (value) => {
    await onFilterUpdate(value);
  },
});

const onFilterUpdate = async (value: PurchaseOrderSearchFilters) => {
  filters.value = value;
  page.value = 1;
  loading.value = true;
  await search();
};

const search = async () => {
  try {
    const request = new PurchaseOrderSearchRequest(query.value);
    request.page = page.value;
    request.pageSize = pageSize.value;
    if (sortField.value === "") {
      sortOrder.value = -1;
    }
    request.sortBy = sortField.value;

    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";
    request.filters = filters.value;

    const response = await purchaseOrderSearch(request);
    purchaseOrders.value = response.purchaseOrders;
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};

const downloadPdf = async (id: string) => {
  const url = await getPurchaseOrderUrl(id);

  if (url && url.length > 0) {
    window.open(url, "_blank");
  } else {
    toast.add({
      severity: "error",
      summary: t("common.error"),
      detail: t("invoice.download-link-missing"),
    });
  }
};

const onUpdateSortPurchaseOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  search();
};

const onUpdatePageSize = (value: number) => {
  pageSize.value = value;
  loading.value = true;
  search();
};
</script>

<style scoped lang="scss">
.c-transition {
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
.c-purchase-search-result {
  margin: var(--default-content-margin);
}
:deep(.c-compact-datatable.p-datatable .p-datatable-tbody > tr > td.c-pdf-icon),
:deep(.c-compact-datatable.p-datatable .p-datatable-thead > tr > th.c-pdf-icon) {
  .p-column-title {
    visibility: hidden;
  }
}
</style>
