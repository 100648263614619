<template>
  <div class="mt-3">
    <FloatLabelDropdownPanel
      ref="commentInputRef"
      id="comment"
      :selectedItemName="comment?.trim() ?? ''"
      :label="t('purchase.comment.label')"
      :selectLabel="t('placeholder.type', { property: t('purchase.comment.label').toLowerCase() })"
      @toggleDropdownPanel="showCommentPanel"
      maxlength="15000"
      dataTestId="purchase-comment"
      class="c-comment"
      :class="{ 'p-disabled': !allowEdit }"
      :disabled="!allowEdit"
    />

    <CommentPanel ref="commentPanelRef" v-model:comment="comment" @tabKeyPressed="tabKeyPressed" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import CommentPanel from "./CommentPanel.vue";

const { t } = useI18n();

const props = defineProps<{
  purchaseOrderStatus?: PurchaseOrderStatus;
  inGoodsReception: boolean;
}>();

const comment = defineModel<string>("comment");

const allowEdit = computed<boolean>(() => {
  if (props.inGoodsReception) return false;
  return (
    !props.purchaseOrderStatus ||
    props.purchaseOrderStatus === PurchaseOrderStatus.Open ||
    props.purchaseOrderStatus === PurchaseOrderStatus.PartiallyReceived
  );
});

const commentPanelRef = ref();
const showCommentPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }

  commentPanelRef.value.toggle(event);
};
const commentInputRef = ref();
const tabKeyPressed = () => commentInputRef.value.$el?.querySelector("button")?.focus();
</script>
<style lang="scss" scoped>
:deep(.c-comment) .p-dropdown-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10rem;
}
</style>
