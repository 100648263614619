<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <Toolbar
          @onAddPurchase="router.push({ name: 'purchase-add' })"
          @onOpenReceivedGoods="router.push({ name: 'received-goods' })"
        />
      </div>
      <div>
        <div class="c-header-filter-container c-header-icon-container">
          <FilterOverview v-model:filters="filtersComputed" />
          <PrimeButton class="c-circular-button" @click="emit('onToggleFilterSidebar')" data-testid="c-show-sidebar">
            <i class="pi pi-filter c-default-button c-circular-icon"></i>
            <span class="px-3">{{
              showSidebar ? t("purchase.search.hide-filter") : t("purchase.search.show-filter")
            }}</span>
          </PrimeButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PurchaseOrderSearchFilters } from "@/models/search/purchase-order/PurchaseOrderSearchFilters";
import { computed } from "vue";
import { useRouter } from "vue-router";
import Toolbar from "./Toolbar.vue";
import FilterOverview from "./FilterOverview.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps<{
  showSidebar: boolean;
  filters: PurchaseOrderSearchFilters;
}>();

const emit = defineEmits<{
  (e: "onToggleFilterSidebar"): void;
  (e: "update:filters", value: PurchaseOrderSearchFilters): void;
}>();
const router = useRouter();

const filtersComputed = computed({
  get() {
    return props.filters;
  },
  set(value: PurchaseOrderSearchFilters) {
    emit("update:filters", value);
  },
});
</script>
