<template>
  <div class="c-chips">
    <ul class="c-chips-multiple-container" v-if="hasActiveFilters">
      <li class="c-chips-token-clear-filter">
        <span>{{ t("purchase.search.clear-filter") }}</span>
        <span class="c-chips-token-icon pi pi-times-circle" @click="clearFilters" data-testid="clear-filter-btn">
        </span>
      </li>
      <FilterItem
        v-if="filters.purchaseOrderStatuses.length > 0"
        :values="filters.purchaseOrderStatuses.map((status) => t(`purchase.status.${status.toLowerCase()}`))"
        filterType="purchaseOrderStatuses"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.productNumbers.length > 0"
        :values="filters.productNumbers"
        filterType="productNumbers"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.supplierNumbers.length > 0"
        filterType="supplierNumbers"
        :values="filters.supplierNumbers"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.registeredBy.length > 0"
        filterType="registeredBy"
        :values="filters.registeredBy"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.fromDate !== null"
        filterType="fromDate"
        :values="d(filters.fromDate)"
        @removeFilter="removeFilter"
      />
      <FilterItem
        v-if="filters.toDate !== null"
        filterType="toDate"
        :values="d(filters.toDate)"
        @removeFilter="removeFilter"
      />
    </ul>
  </div>
</template>

<script setup lang="ts">
import { PurchaseOrderSearchFilters } from "@/models/search/purchase-order/PurchaseOrderSearchFilters";
import FilterItem from "./FilterItem.vue";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t, d } = useI18n();

const props = defineProps<{
  filters: PurchaseOrderSearchFilters;
}>();

const emit = defineEmits<{
  (event: "update:filters", value: PurchaseOrderSearchFilters): void;
}>();

const removeFilter = (filterType: keyof PurchaseOrderSearchFilters, index: number) => {
  const filters = { ...props.filters };
  const filterToRemove = filters[filterType];

  if (Array.isArray(filterToRemove)) {
    filterToRemove.splice(index, 1);
  } else if (filterType === "fromDate" || filterType === "toDate") {
    filters[filterType] = null;
  }

  emit("update:filters", filters);
};

const clearFilters = () => {
  const filters = new PurchaseOrderSearchFilters();
  emit("update:filters", filters);
};

const hasActiveFilters = computed<boolean>(() => {
  return (
    props.filters.purchaseOrderStatuses.length > 0 ||
    props.filters.productNumbers.length > 0 ||
    props.filters.supplierNumbers.length > 0 ||
    props.filters.registeredBy.length > 0 ||
    props.filters.fromDate !== null ||
    props.filters.toDate !== null
  );
});
</script>

<style scoped lang="scss">
.c-chips {
  display: inline-flex;
}

.c-chips-multiple-container {
  padding: 0.25rem 0.5rem;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.c-chips-token-clear-filter {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  color: var(--primary-color-text);
  border-radius: 1rem;
  background-color: var(--chips-bg-primary);
  display: inline-flex;
  align-items: center;
}

.c-chips-token-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}
</style>
