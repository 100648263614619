<template>
  <div id="purchase-navbar-shortcuts" v-if="useTeleport">
    <Teleport to="#c-navbar-title">
      <h1>{{ editMode ? t("purchase.header.edit-purchase") : t("purchase.header.add-purchase") }}</h1>
    </Teleport>
  </div>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar flex flex-wrap" :class="{ 'c-fixed-right': editMode }">
      <div class="c-header-icon-container">
        <div v-tooltip.bottom="saveButtonTooltip">
          <PrimeButton
            class="c-circular-button mr-3"
            @click="emit('saveClicked')"
            data-testid="btn-save"
            :disabled="!isPurchaseOrderChanged || isSaving || !allowEditPurchaseOrder"
          >
            <i class="pi pi-check c-success-button c-circular-icon"></i>
            <span class="px-3">{{ t("common.save") }}</span>
          </PrimeButton>
        </div>
        <PrimeButton class="c-circular-button" @click="emit('onCancel')" :disabled="isSaving" data-testid="btn-cancel">
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-3">{{ t("common.cancel") }}</span>
        </PrimeButton>
        <PrimeButton
          v-if="editMode"
          class="c-circular-button ml-3"
          @click="onConfirmDelete"
          :disabled="!allowDelete"
          data-testid="btn-delete"
        >
          <i class="pi pi-trash c-delete-button c-circular-icon"></i>
          <span class="px-3">{{ t("common.delete") }}</span>
        </PrimeButton>
        <ConfirmPopup data-testid="purchase-delete-confirm"></ConfirmPopup>
      </div>
    </div>
  </div>
  <UnsavedChangesDialog
    position="top"
    :visible="props.unsavedChangesDialogVisible"
    :dialogHeader="t('common.unsaved-changes-header')"
    @cancelClicked="emit('stayOnPage')"
    @discardClicked="emit('routeToPurchaseOrderSearch')"
    @saveClicked="emit('saveClicked')"
    ><template #content>{{ t("common.unsaved-changes-text") }}</template>
    <template #cancelBtn>{{ t("common.cancel") }}</template>
    <template #discardBtn>{{ t("common.discard") }}</template>
    <template #saveBtn>{{ t("common.save") }}</template>
  </UnsavedChangesDialog>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import { computed } from "vue";
import { UnsavedChangesDialog } from "@cumulus/components";

const { t } = useI18n();
const confirm = useConfirm();

const useTeleport = !(import.meta.env.VITE_APP_STANDALONE === "true" || import.meta.env.MODE === "test");

const props = defineProps<{
  isPurchaseOrderChanged: boolean;
  isSaving: boolean;
  allowEditPurchaseOrder: boolean;
  inGoodsReception?: boolean;
  purchaseOrderStatus: PurchaseOrderStatus;
  editMode?: boolean;
  unsavedChangesDialogVisible: boolean;
}>();

const emit = defineEmits<{
  (e: "saveClicked"): void;
  (e: "deleteClicked"): void;
  (e: "onCancel"): void;
  (e: "stayOnPage"): void;
  (e: "routeToPurchaseOrderSearch"): void;
}>();

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("purchase.delete.confirm-message"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("deleteClicked");
    },
  });
};

const allowDelete = computed<boolean>(() => {
  if (props.inGoodsReception) return false;
  if (!props.editMode) return false;
  if (props.isSaving) return true;
  if (props.purchaseOrderStatus && props.purchaseOrderStatus === PurchaseOrderStatus.Open) return true;

  return false;
});

const saveButtonTooltip = computed(() => {
  return props.isPurchaseOrderChanged
    ? { value: "" }
    : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});
</script>
<style lang="scss" scoped>
.c-toolbar.c-fixed-right {
  position: fixed;
  right: 1rem;
}

.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
