<template>
  <DataTable
    ref="contactTableRef"
    :value="contacts"
    scrollHeight="flex"
    dataKey="id"
    selectionMode="single"
    @row-select="onRowSelect"
    :rowHover="true"
    data-testid="contact-search-list"
    sortField="name"
    :sortOrder="1"
    stripedRows
    autoLayout
    :paginator="true"
    :rows="10"
  >
    <template #header>
      <span class="p-input-icon-left">
        <i class="pi pi-search absolute mt-2" />
        <InputText
          id="contactSearchInput"
          ref="searchInputRef"
          v-model="query"
          v-debounce:200="search"
          :placeholder="t('purchase.contact.search-placeholder')"
          @focus="selectAllOnFocus"
          @keydown.enter="searchInputEnterKey"
          @keypress.tab="focusFirstSearchResult"
          @keydown.down="focusFirstSearchResult"
          class="w-full pl-5"
          autocomplete="off"
          data-testid="contact-search-list-input"
        />
      </span>
    </template>

    <template>
      <Column field="firstName" :header="t('purchase.contact.firstname')"> </Column>
      <Column field="lastName" :header="t('purchase.contact.firstname')"> </Column>
      <Column field="phoneNumber" :header="t('purchase.contact.phone')"> </Column>
      <Column field="email" :header="t('purchase.contact.email')"> </Column>
      <Column field="isDefaultContact" :header="t('purchase.contact.default-contact')" class="text-center">
        <template #body="{ data }">
          <i v-if="data.isDefaultContact" class="pi pi-check-circle"></i>
        </template>
      </Column>
    </template>

    <template #empty>
      <div>
        {{
          query.length < 1
            ? t("purchase.contact.empty-search-query")
            : t("purchase.contact.no-contacts-found", { search: query })
        }}
      </div>
    </template>

    <template #footer> </template>
  </DataTable>

  <div class="flex justify-content-between align-items-center flex-wrap">
    <div class="flex">
      {{
        t("purchase.supplier.search-footer-result", {
          searchCount: contacts.length,
          totalSearchCount: totalHits,
        })
      }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, nextTick, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { DataTableRowSelectEvent } from "primevue/datatable";
import { PurchaseOrderContact } from "@/models/purchase-order/PurchaseOrderContact";
import { SupplierContact } from "@/models/supplier/SupplierContact";

const { t } = useI18n();

const props = defineProps<{ contact: PurchaseOrderContact; supplierContacts: SupplierContact[] }>();
const emit = defineEmits<{ (e: "selectedContact", value: SupplierContact): void }>();

const contactTableRef = ref();

const focusFirstSearchResult = (event: Event) => {
  event.stopPropagation();
  contactTableRef.value.$el.querySelector("tbody tr:first-of-type").focus();
};

const searchInputEnterKey = (event: Event) => {
  event.stopPropagation();

  if (query.value.length < 1) {
    focusFirstSearchResult(event);
    return;
  }

  //if only one result, select it
  if (contacts.value.length === 1) {
    emit("selectedContact", contacts.value[0]);
    return;
  }

  //Create a new contact if no result
  if (contacts.value.length === 0) {
    const supplierContact = new SupplierContact();
    const name = query.value.trim().split(" ");
    supplierContact.lastName = name.length > 1 ? (name.pop() as string) : "";
    supplierContact.firstName = name.join(" ");
    emit("selectedContact", supplierContact);
  }

  focusFirstSearchResult(event);
  return;
};

const onRowSelect = async (event: DataTableRowSelectEvent) => {
  const supplierContact = event.data as SupplierContact;
  if (!supplierContact) return;

  emit("selectedContact", supplierContact);
};

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const searchInputRef = ref();
nextTick(() => {
  searchInputRef.value.$el.focus();
});

const totalHits = ref(0);
const query = ref<string>("");

const contacts = ref<SupplierContact[]>([]);
const search = async (q: string) => {
  if (q.length < 1 || q.trim() === "*") {
    contacts.value = props.supplierContacts;
    totalHits.value = props.supplierContacts.length;
    return;
  }
  query.value = q;

  contacts.value = props.supplierContacts.filter(
    (c) =>
      c.firstName.toLowerCase().includes(q.toLowerCase()) ||
      c.lastName.toLowerCase().includes(q.toLowerCase()) ||
      (c.firstName.toLowerCase() + " " + c.lastName.toLowerCase()).includes(q.toLowerCase()) ||
      c.phoneNumber.toLowerCase().includes(q.toLowerCase()) ||
      c.email.toLowerCase().includes(q.toLowerCase())
  );

  totalHits.value = props.supplierContacts.length;
};

onMounted(async () => {
  const name = props.contact?.firstName + " " + props.contact?.lastName;
  await search(name.trim());
});
</script>

<style lang="scss">
.c-contact-panel {
  .c-no-search-result {
    color: var(--pink-700);
  }

  .p-datatable-header {
    padding: 0;
    margin-bottom: 0.5rem;

    .p-inputtext {
      border: none;
      border-bottom: var(--floating-input-border-on-focus);
      border-radius: 0;

      &:focus {
        box-shadow: none;
        border-color: var(--active-component-color);
      }
    }
    .p-input-icon-left > i:first-of-type {
      color: var(--active-component-color);
      font-weight: bolder;
    }
  }

  .p-datatable-footer {
    padding: 0.75rem 0;
  }
}
</style>
