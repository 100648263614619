<template>
  <OverlayPanel
    ref="supplierPanelRef"
    appendTo="body"
    :dismissable="true"
    id="supplier_overlay_panel"
    :breakpoints="{ '960px': '75vw', '640px': '100vw', '90000px': '800px' }"
    class="c-supplier-panel"
    @keydown.esc.stop="hideOverlayPanel"
  >
    <SupplierList :supplier="props.supplier" @selectedSupplier="selectedSupplier" />
  </OverlayPanel>
</template>

<script setup lang="ts">
import { ref } from "vue";
import SupplierList from "./SupplierList.vue";
import { Supplier } from "@/models/supplier/Supplier";
import { PurchaseOrderSupplier } from "@/models/purchase-order/PurchaseOrderSupplier";

const supplierPanelRef = ref();
const props = defineProps<{ supplier: PurchaseOrderSupplier }>();
const emit = defineEmits<{ (e: "supplierSelected", value: Supplier): void }>();

const toggle = (event: Event) => supplierPanelRef.value.toggle(event);
const show = (event: Event) => supplierPanelRef.value.show(event);
const hideOverlayPanel = () => supplierPanelRef.value.hide();

defineExpose({ toggle: toggle, show: show });

const selectedSupplier = (supplier: Supplier) => {
  supplierPanelRef.value.hide();
  emit("supplierSelected", supplier);
  document.getElementById("contact-search-input")?.focus();
};
</script>

<style scoped lang="scss">
.c-supplier-panel .p-overlaypanel-content {
  height: auto;
}
</style>
