<template>
  <CumulusRightSidebar :showSidebar="showSidebarComputed" :enableDragover="false" :sidebarWidth="260">
    <template v-slot:header>
      <div class="c-filter-header-container">
        <i class="pi pi-sliders-h"></i>
        <h1>{{ t("purchase.search.filter") }}</h1>
      </div>
    </template>
    <template v-slot:body>
      <h2 class="text-center">{{ t("purchase.search.filter-purchase-order-date") }}</h2>

      <div class="formgrid grid">
        <div class="col-6 field text-center">
          <CumulusDatePicker
            v-model:date="fromDateComputed"
            :label="t('purchase.search.filter-date-from', { symbol: ':' })"
            :dataTestId="`from-date-filter`"
            :debounceTime="400"
          />
        </div>
        <div class="col-6 field text-center">
          <CumulusDatePicker
            v-model:date="toDateComputed"
            :label="t('purchase.search.filter-date-to', { symbol: ':' })"
            :dataTestId="`to-date-filter`"
            :debounceTime="400"
          />
        </div>
      </div>

      <PrimeButton
        @click="setDateFilterLastDays(30)"
        :label="t('purchase.search.filter-last-thirty-days')"
        data-testid="last-30-days-filter-btn"
        class="w-full"
      />

      <hr class="c-seperator-line" />

      <h2 class="text-center">{{ t("purchase.search.filter-status") }}</h2>
      <div
        v-for="(value, index) in allStatusesComputed"
        :key="index"
        class="flex justify-content-between relative align-items-center p-2 border-round-md"
        :class="{ 'c-list-row-bg mt-1': index % 2 === 0, 'c-list-alternate-row-bg mt-1': index % 2 === 1 }"
      >
        <div class="centered-child">
          <label class="font-light" :for="'purchase-status-' + value.status.toLowerCase()">{{
            t(`purchase.status.${value.status.toLowerCase()}`)
          }}</label>
        </div>
        <div class="ml-auto">
          <Checkbox
            v-model="statusesComputed"
            :value="value.status"
            :inputId="'purchase-status-' + value.status.toLowerCase()"
            :data-testid="'purchase-status-' + value.status.toLowerCase()"
          />
        </div>
      </div>

      <hr class="c-seperator-line" />

      <h2 class="text-center">
        <label for="supplier-number-filter">
          {{ t("purchase.search.filter-supplier-number") }}
        </label>
      </h2>
      <Chips
        v-model="supplierNumbersComputed"
        class="c-supplier-numbers"
        data-testid="supplier-number-filter"
        inputId="supplier-number-filter"
      />
      <hr class="c-seperator-line" />

      <h2 class="text-center">
        <label for="product-number-filter">{{ t("purchase.search.filter-product-number") }}</label>
      </h2>
      <Chips
        v-model="productNumbersComputed"
        class="c-product-numbers"
        data-testid="product-number-filter"
        inputId="product-number-filter"
      ></Chips>
      <hr class="c-seperator-line" />

      <h2 class="text-center">
        <label for="registered-by-filter">
          {{ t("purchase.search.filter-registered-by") }}
        </label>
      </h2>
      <Chips
        ref="chipsRef"
        v-model="registeredByComputed"
        class="c-product-numbers"
        data-testid="registered-by-filter"
        inputId="registered-by-filter"
      ></Chips>
    </template>
  </CumulusRightSidebar>
</template>

<script setup lang="ts">
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import { PurchaseOrderSearchFilters } from "@/models/search/purchase-order/PurchaseOrderSearchFilters";
import Chips from "primevue/chips";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { CumulusRightSidebar } from "@cumulus/components";
import { CumulusDatePicker } from "@cumulus/components";

const { t } = useI18n();
const chipsRef = ref();

const props = defineProps<{
  showSidebar: boolean;
  filters: PurchaseOrderSearchFilters;
}>();

const emit = defineEmits<{
  (event: "update:showSidebar", value: boolean): void;
  (event: "update:filters", value: PurchaseOrderSearchFilters): void;
}>();

const showSidebarComputed = computed<boolean>({
  get: () => props.showSidebar,
  set: (value) => emit("update:showSidebar", value),
});

const allStatusesComputed = computed(() => {
  const allStatuses = Object.values(PurchaseOrderStatus);
  return allStatuses.map((status, index) => {
    return {
      status: status,
      index: index,
    };
  });
});

const statusesComputed = computed<PurchaseOrderStatus[]>({
  get: () => props.filters.purchaseOrderStatuses,
  set: (value) => {
    const filters = { ...props.filters, purchaseOrderStatuses: value };
    emit("update:filters", filters);
  },
});

const dateFormatted = (value: string): string => {
  const date = value ? new Date(value) : new Date();
  const yyyy = date.getFullYear();
  const month = date.getMonth() + 1; // Months start at 0 index!
  const day = date.getDate();
  const mm = month < 10 ? "0" + month : month;
  const dd = day < 10 ? "0" + day : day;
  return yyyy + "-" + mm + "-" + dd;
};

const fromDateComputed = computed<string>({
  get: () => {
    if (props.filters.fromDate) {
      return dateFormatted(props.filters.fromDate);
    }
    return "";
  },
  set: (value) => {
    let fromDate: string | null = null;
    if (value === null || value === "") {
      if (props.filters.fromDate === null) return;
    } else {
      fromDate = new Date(value).toJSON();
      if (props.filters.fromDate === fromDate) return;
    }

    const filters = { ...props.filters, fromDate: fromDate };
    emit("update:filters", filters);
  },
});

const toDateComputed = computed<string>({
  get: () => {
    if (props.filters.toDate) {
      return dateFormatted(props.filters.toDate);
    }
    return "";
  },
  set: (value) => {
    let toDate: string | null = null;
    if (value === null || value === "") {
      if (props.filters.toDate === null) return;
    } else {
      toDate = new Date(value).toJSON();
      if (props.filters.toDate === toDate) return;
    }

    const filters = { ...props.filters, toDate: toDate };
    emit("update:filters", filters);
  },
});

const setDateFilterLastDays = (days: number) => {
  const toDate = new Date();
  const fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - days);

  const filters = { ...props.filters, toDate: toDate.toJSON(), fromDate: fromDate.toJSON() };
  emit("update:filters", filters);
};

const supplierNumbersComputed = computed<string[]>({
  get: () => props.filters.supplierNumbers,
  set: (value) => {
    const filters = { ...props.filters, supplierNumbers: value };
    emit("update:filters", filters);
  },
});

const productNumbersComputed = computed<string[]>({
  get: () => props.filters.productNumbers,
  set: (value) => {
    const filters = { ...props.filters, productNumbers: value };
    emit("update:filters", filters);
  },
});

const registeredByComputed = computed<string[]>({
  get: () => props.filters.registeredBy,
  set: (value) => {
    const filters = { ...props.filters, registeredBy: value };
    emit("update:filters", filters);
  },
});
</script>

<style scoped lang="scss">
.c-seperator-line {
  border: 1px solid var(--seperator-line);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.centered-child {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.c-list-row-bg {
  background-color: var(--list-row-bg);
}

.c-list-alternate-row-bg {
  background-color: var(--list-alternate-row-bg);
}

h2,
label {
  font-family: var(--font-family);
}
h2 {
  font-size: 14px;
}

.c-filter-header-container {
  justify-content: center;
  display: flex;
  align-items: center;
  h1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.c-filter-header-container i {
  margin-right: 0.5rem;
}

.c-supplier-numbers {
  width: 100%;
}
.c-supplier-numbers :deep(ul) {
  width: 100%;
}
.c-product-numbers {
  width: 100%;
}
.c-product-numbers :deep(ul) {
  width: 100%;
}
</style>
