<template>
  <FloatLabelDropdown
    id="currency"
    dataTestId="purchase-order-currency"
    v-model:value="selectedCurrency"
    :options="dropdownOptions"
    :class="{ 'p-invalid': val.currencyIso.$error, 'p-disabled': !allowEdit }"
    :label="t(`purchase.currency`)"
    :placeholder="t('placeholder.select', { property: t('purchase.currency').toLowerCase() })"
    :isFloatingLabel="isFloatingLabel"
    :disabled="!allowEdit"
  />

  <small id="purchase-currency-help" data-testid="purchase-currency-error" class="ml-4 p-error" v-if="val.$error">{{
    val.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { Currency } from "@/models/currency/Currency";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";

const { t } = useI18n();

const props = defineProps<{
  currencyIso: string;
  currencies: Currency[];
  purchaseOrderStatus?: PurchaseOrderStatus;
}>();

const emit = defineEmits<{
  (e: "update:currencyIso", value: string): void;
}>();

const dropdownOptions = computed(() => {
  return props.currencies.map((currency) => {
    return { name: currency.currencyIso, value: currency.id };
  });
});

const selectedCurrency = computed<string>({
  get: () => {
    return props.currencies.find((c) => c.currencyIso === props.currencyIso)?.id ?? "";
  },
  set: (id: string) => {
    if (id === "") {
      return;
    }

    const currency = props.currencies.find((c) => c.id === id);
    if (currency) {
      emit("update:currencyIso", currency.currencyIso);
    }
  },
});

const rules = {
  currencyIso: {
    required,
  },
};

const val = useVuelidate(rules, props);

const allowEdit = computed<boolean>(() => {
  return false; //Not supported yet... return !props.purchaseOrderStatus || props.purchaseOrderStatus === PurchaseOrderStatus.Open;
});

const isFloatingLabel = computed(() => {
  if (selectedCurrency.value === undefined) {
    return false;
  }

  return selectedCurrency.value !== "";
});
</script>
