<template>
  <CustomToast />
  <div class="c-toolbar mt-4" data-testid="received-goods-header">
    <div class="c-header-icon-container">
      <PrimeButton class="c-circular-button" @click="onBack" data-testid="btn-back-nav">
        <i class="pi pi-times c-warning-button c-circular-icon"></i>
        <span class="px-3">{{ t("common.back") }}</span>
      </PrimeButton>
    </div>
  </div>
  <div class="c-card">
    <DataTable
      :value="receivedGoods"
      :loading="isLoading"
      data-testid="received-goods-list"
      class="c-compact-datatable"
    >
      <Column
        field="goodsReceptionNumber"
        :header="t('received-goods.goods-reception-number')"
        class="c-margin-auto text-center"
      />
      <Column field="warehouseName" :header="t('received-goods.warehouse-name')" class="c-margin-auto text-center" />
      <Column field="receivedDate" :header="t('received-goods.received-date')" class="c-margin-auto text-center">
        <template #body="slotProps">
          <div>{{ d(slotProps.data.receivedDate, "long") }}</div>
        </template>
      </Column>
      <Column field="supplierNumber" :header="t('received-goods.supplier-number')" class="c-margin-auto text-center" />
      <Column field="supplierName" :header="t('received-goods.supplier-name')" class="c-margin-auto text-center" />
      <Column
        field="purchaseOrderNumber"
        :header="t('received-goods.purchase-order-number')"
        class="c-margin-auto text-center"
      />
      <Column
        field="documentReference"
        :header="t('received-goods.document-reference')"
        class="c-margin-auto text-center"
      />
      <Column field="productNumber" :header="t('received-goods.product-number')" class="c-margin-auto text-center" />
      <Column field="productName" :header="t('received-goods.product-name')" class="c-margin-auto text-center" />
      <Column field="freightCost" :header="t('received-goods.freight-cost')" class="c-margin-auto text-right">
        <template #body="{ data }">
          <div>{{ n(data.freightCost, "currency", data.purchaseOrderCurrencyIso) }}</div>
        </template>
      </Column>
      <Column field="purchasePrice" :header="t('received-goods.purchase-price')" class="c-margin-auto text-right">
        <template #body="{ data }">
          <div>{{ n(data.purchasePrice, "currency", data.purchaseOrderCurrencyIso) }}</div>
        </template>
      </Column>
      <Column field="receivedCost" :header="t('received-goods.received-cost')" class="c-margin-auto text-right">
        <template #body="{ data }">
          <div>{{ n(data.receivedCost, "currency", data.goodsReceptionCurrencyIso) }}</div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script setup lang="ts">
import { useGoodsReception } from "@/api/goods-reception/GoodsReceptionService";
import { ReceivedGoods } from "@/models/purchase-order/ReceivedGoods";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { CustomToast } from "@cumulus/toast";

const { t, d, n } = useI18n();
const router = useRouter();
const receivedGoods = ref<ReceivedGoods[]>([]);
const goodsReceptionService = useGoodsReception();
const isLoading = ref(false);

useCumulusToast(useToast());

const onBack = () => {
  router.push({ name: "purchase-search", params: { query: "*" } });
};

onMounted(() => {
  fetchReceivedGoods();
});

const fetchReceivedGoods = async () => {
  isLoading.value = true;
  receivedGoods.value = await goodsReceptionService.getReceivedGoods();
  isLoading.value = false;
};
</script>

<style lang="scss" scoped>
.c-compact-datatable :deep(.p-datatable-thead > tr > th.c-margin-auto > div > span) {
  margin: auto;
}
</style>
