<template>
  <div class="mt-3">
    <FloatLabelInput
      id="contact-email"
      :label="t('purchase.contact.email-label')"
      v-model:value="purchaseOrderReferenceComputed"
      :selectAllOnFocus="true"
      :placeholder="t('placeholder.type', { property: t('purchase.contact.email-label').toLowerCase() })"
      :dataTestId="'contact-email'"
      :class="{ 'p-disabled': !allowEdit }"
      :disabled="!allowEdit"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";

const { t } = useI18n();

const props = defineProps<{
  email: string;
  purchaseOrderStatus?: PurchaseOrderStatus;
}>();

const emit = defineEmits<{
  (e: "update:email", value: string): void;
}>();

const purchaseOrderReferenceComputed = computed<string>({
  get: () => {
    return props.email ?? "";
  },
  set: (value) => {
    emit("update:email", value);
  },
});
const allowEdit = computed<boolean>(() => {
  return !props.purchaseOrderStatus || props.purchaseOrderStatus === PurchaseOrderStatus.Open;
});
</script>

<style lang="scss" scoped>
.c-inline-title {
  min-width: 7.1rem;
}
</style>
