{
  "validations": {
    "required": "Value is required",
    "email": "Must be a valid e-mail address",
    "minLength": "Minimum length is {min}",
    "exists": "{property} already exists",
    "nonEmptyGuid": "@:validations.required",
    "at-least-one": "At least one {property} must be added",
    "contact-person-required": "Firstname, lastname or email is required"
  },
  "placeholder": {
    "select": "Select {property}",
    "type": "Type {property}"
  },
  "common": {
    "print": "Print",
    "add": "Add",
    "save": "Save",
    "cancel": "Cancel",
    "close": "Close",
    "search": "Search",
    "clear": "Clear",
    "yes": "Yes",
    "no": "No",
    "delete": "Delete",
    "create": "Create",
    "options": "Options",
    "reload": "Reload",
    "reset": "Reset columns",
    "created": "Created",
    "new-line": "New line",
    "error": "Error",
    "current-page-template": "Showing {first} to {last} of {totalRecords} purchase orders",
    "loading": "Loading, please wait...",
    "unsaved-changes-header": "Unsaved changes",
    "unsaved-changes-text": "You have unsaved changes. Save your changes or Discard for leaving without saving your changes. Choose Cancel to continue editing",
    "discard": "Discard",
    "delete-confirm": "Do you want to delete this item?",
    "save-tooltip": "No changes",
    "error-detail": {
      "unknown": "Unknown error",
      "server-error": "Server error",
      "not-found": "Not found",
      "bad-request": "Bad request",
      "error-occured": "An error occured"
    },
    "open-received-goods": "Received goods",
    "back": "Back"
  },
  "purchase": {
    "purchase-header": "Purchase order",
    "pdf": "PDF",
    "printers": "Printers",
    "print-success": "Purchase order confirmation sent to printer successfully",
    "print-success-detail": "Purchase order confirmation is being printed to {printerName}",
    "printix": "Printix",
    "setup-printix-message": "Printix isnt setup yet. Go here to setup {0}",
    "created": "Registered",
    "by": "By",
    "empty-list": "Use search to add products",
    "no-products-found": "No products found",
    "warehouse": "Warehouse",
    "currency": "Currency",
    "incoterms": "Incoterms",
    "purchasing-line": "Purchasing line",
    "add-purchase": "New purchase",
    "name": "Name",
    "open-purchase-orders": "Open purchase orders",
    "info-tabs": {
      "first-tab": "Supplier",
      "second-tab": "Delivery to",
      "menu-change-delivery-address": "Change delivery address"
    },
    "address": {
      "dialog-header": "Change delivery address",
      "lines": "Address",
      "country": "Country",
      "city": "City",
      "postal-code": "Postal code",
      "postal-code-city": "Postal code / City"
    },
    "status": {
      "open": "Open",
      "ingoodsreception": "In goods reception",
      "partiallyreceived": "Partially received",
      "received": "Received"
    },    
    "supplier": {      
      "search-label": "Supplier",
      "search-placeholder": "Search for suppliers",
      "empty-search-query": "Please search for a supplier",
      "no-suppliers-found": "'{search}' not found",
      "search-footer-result": "Show {searchCount} of {totalSearchCount}",
      "number": "Sup.number",
      "name": "Name",
      "address": "Address",
      "city": "City",
      "phone": "Phone{symbol}",
      "email": "Email{symbol}",
      "select-supplier": "Select supplier",
      "no-supplier": "No supplier selected"
    },
    "product": {
      "search-placeholder": "Search for products",
      "number": "Product number",
      "name": "Product name",
      "quantity": "Quantity",
      "price-excl-vat": "Price excl.vat",
      "shipping-date": "Shipping date",
      "inventory": "Inventory",
      "available-stock": "Available stock",
      "order-reserve": "Order reserve",
      "gtin": "GTIN(EAN)",
      "pos-nr": "Posnr",
      "received": "Received",
      "eta-date": "ETA date",
      "sum-excl-vat": "Sum excl.vat",
      "only-in-demand": "Only products in demand",
      "supplier-missing-summary": "Supplier is missing",
      "supplier-missing-detail": "Supplier is missing, please add supplier before adding products",
      "quantity-back-order": "Qty back order",
      "supplier-cost-price": "Supplier's cost price",
      "supplier-product-number": "Supplier's product number",
      "available": "Available",
      "in-demand": "In demand",
      "accessories": "Accessories",
      "product": "Product",
      "alternatives": "Alternatives",
      "open-product": "Open product",
      "group-level": "Group level:",
      "dimension-and-weight": "Dimension & weight:",
      "manufacturer": "Manufacturer",
      "description": "Description",
      "type": "Product type",
      "unit": "Unit",
      "dimensions": {
        "label" : "Dimensions",
        "height": "Height",
        "width": "Width",
        "length": "Length",
        "weight": "Weight"
      },
      "info-dialog-header": "Product Info"
    },
    "title": {
      "add-purchase": "Add Purchase",
      "edit-purchase": "Edit Purchase",
      "search-purchase": "Search Purchase",
      "purchase-settings": "Purchase Settings",
      "received-goods": "Received Goods"
    },
    "header": {
      "add-purchase": "Add purchase order",
      "edit-purchase": "Update purchase order"
    },
    "search-header": {
      "add-purchase": "Add purchase",
      "purchases": "Purchases"
    },
    "purchase-reference": {
      "label": "Reference"
    },
    "contact": {
      "label": "Contact person",
      "search-placeholder": "Search for contact person or write name of a custom contact",  
      "no-contacts-found": "No contacts found. Write name and click 'Enter' for custom contact.",
      "empty-search-query": "Please search for a contact person or write name of a custom contact",    
      "firstname": "First name",
      "lastname": "Last name",
      "phone": "Phone",
      "email": "Email",
      "default-contact": "Default contact",
      "email-label": "E-mail",
      "is-required": "Name or e-mail is required"
    },
    "payment-term": "Payment term",
    "freight-method": "Freight method",
    "shipping-price": "Shipping price",
    "delivery-option": {
      "label": "Delivery",
      "total": "Total delivery"
    },
    "comment": {
      "label": "Comment",
      "placeholder": "Add a comment.."
    },
    "add": {
      "title": "Create purchase",
      "toast": {
        "success": {
          "summary": "Saved",
          "detail": "Purchase order {purchaseOrderNumber} is now saved."
        },
        "validation": {
          "summary": "Validation failed",
          "detail": "All red fields must be corrected"
        }
      }
    },
    "edit": {
      "title": "Purchase information",
      "toast": {
        "success": {
          "summary": "Purchase was updated",
          "detail": "Changes are soon ready"
        },
        "validation": {
          "summary": "Validation failed",
          "detail": "All red fields must be corrected"
        }
      }
    },
    "delete": {
      "confirm-message": "Do you want to delete purchase?",
      "toast": {
        "success": {
          "summary": "Deleting purchase",
          "detail": "Purchase is soon deleted"
        }
      }
    },
    "purchase-number": {
      "label": "Purchase",
      "assigned": "Gets assigned when its created"
    },
    "search": {
      "registered": "Registered",
      "registered-by": "Registered by",
      "supplier-name": "Supplier name",
      "supplier-number": "Supplier number",
      "purchase-number": "Purchase number",
      "purchase-reference": "Reference",
      "purchase-status": "Status",
      "freight-method": "Freight method",
      "quantity-order-lines": "Purchase lines",
      "total-items-ex-vat": "Total value",
      "total-hits": "No purchase orders found | 1 purchase order found | {totalHits} purchase orders found",
      "currency": "Currency",
      "contribution-margin": "CM %",
      "toast": {
        "open-purchase-failed": "Failed to open purchase order",
        "no-purchase-selected": "No purchase order selected. Select a purchase order and try again."
      },
      "filter": "Filter",
      "filter-date-from": "From{symbol}",
      "filter-date-to": "To{symbol}",
      "filter-purchase-order-date": "Purchase order date",
      "filter-status": "Purchase order status",
      "filter-supplier-number": "Supplier number",
      "filter-product-number": "Product number",
      "filter-registered-by": "Registered by",
      "filter-last-thirty-days": "Last 30 days",
      "show-filter": "Show filter",
      "hide-filter": "Hide filter",
      "clear-filter": "Clear filter",
      "latest-purchase-orders": "Latest purchase orders",
      "po-number": "PO number",
      "supplier": "Supplier",
      "quantity": "Quantity",
      "cost": "Cost",
      "no-po-found": "No purchase orders found for this product",
      "supplier-product-number": "Supplier product number",
      "ean": "EAN",
      "list-price": "List price",
      "cm": "CM %"
    },
    "footer": {
      "freight": "Freight (agreed)",
      "total-items": "Sum items ex. VAT",
      "total-sum": "Purchase order total ex. VAT"
    },
    "context-menu": {
      "edit": "Edit",
      "delete": "Delete",
      "product-info": "Product Info"
    }    
  },
  "goods-reception-history": {
    "received-date": "Received date",
    "initials": "Initials",
    "quantity-received": "Quantity received",
    "batch-number": "Batch number",
    "reference": "Document reference",
    "supplier-cost-price": "Supplier cost price",
    "received-cost": "Received cost",
    "goods-reception-number": "Goods reception number",
    "product-info": "Product Info"
  },
  "received-goods": {
    "goods-reception-number": "Reception number",
    "warehouse-name": "Warehouse name",
    "received-date": "Received date",
    "supplier-number": "Supplier number",
    "supplier-name": "Supplier name",
    "purchase-order-number": "Purchase order number",
    "document-reference": "Document reference",
    "product-number": "Product number",
    "product-name": "Product name",
    "freight-cost": "Freight cost",
    "currency": "Currency",
    "received-cost": "Received cost",
    "purchase-price": "Purchase price"
  }
}
