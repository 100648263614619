<template>
  <div class="mt-3">
    <FloatLabelInput
      id="purchase-order-reference"
      :label="t('purchase.purchase-reference.label')"
      v-model:value="purchaseOrderReferenceComputed"
      :selectAllOnFocus="true"
      :placeholder="t('placeholder.type', { property: t('purchase.purchase-reference.label').toLowerCase() })"
      dataTestId="purchase-order-reference"
      maxlength="8000"
      :disabled="!allowEdit"
      :class="{ 'p-disabled': !allowEdit }"
      autocomplete="off"
    />
  </div>
</template>

<script setup lang="ts">
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  purchaseOrderReference: string;
  purchaseOrderStatus?: PurchaseOrderStatus;
}>();

const emit = defineEmits<{
  (e: "update:purchaseOrderReference", value: string): void;
}>();

const purchaseOrderReferenceComputed = computed<string>({
  get: () => {
    return props.purchaseOrderReference ?? "";
  },
  set: (value) => {
    emit("update:purchaseOrderReference", value);
  },
});

const allowEdit = computed<boolean>(() => {
  return !props.purchaseOrderStatus || props.purchaseOrderStatus === PurchaseOrderStatus.Open;
});
</script>

<style lang="scss" scoped>
.c-inline-title {
  min-width: 7.1rem;
}
</style>
